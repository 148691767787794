import { createTheme } from "@mui/material";

const primaryTextColor = '#2E3246';
const theme = createTheme({
  palette: {
    primary: {
      main: '#1FAE83',
    },
    text: {
      primary: primaryTextColor,
      secondary: '#5E5E7A',
    },
  },
  typography: {
    h1: {
      fontSize: '1.75rem',
    },
    body1: {
      fontWeight: 200,
      color: primaryTextColor,
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 200,
      color: '#747A93',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;
