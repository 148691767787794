import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from "@mui/material/styles";

const HeaderContainer = styled('div')(({theme}) => ({
  padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(30)}`,
  borderBottom: '1px solid #0000001A',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.common.white,
}));

const CheckboxContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const DeviceCheckboxLabel = styled(FormControlLabel)(({theme}) => ({
  color: theme.palette.text.secondary,
}))

export type HeaderProps = {
  onMobileToggled: (on: boolean) => any,
  onTabletToggled: (on: boolean) => any,
  onDesktopToggled: (on: boolean) => any,
};

export function Header(props: HeaderProps) {
  return (
    <HeaderContainer>
      <div>
        <Typography variant="h1" sx={{color: 'text.primary'}}>NCR D3 Digital Banking</Typography>
      </div>
      <CheckboxContainer>
        <DeviceCheckboxLabel
          label="Mobile"
          control={(
            <Checkbox
              onChange={(e) => props.onMobileToggled(e.target.checked)}
              size="medium"
              defaultChecked
            />
          )}
        />
        <DeviceCheckboxLabel
          label="Desktop"
          control={(
            <Checkbox
              onChange={(e) => props.onDesktopToggled(e.target.checked)}
              size="medium"
              defaultChecked
            />
          )}
        />
        <DeviceCheckboxLabel
          label="Tablet"
          control={(
            <Checkbox
              onChange={(e) => props.onTabletToggled(e.target.checked)}
              size="medium"
              defaultChecked
            />
          )}
        />
      </CheckboxContainer>
    </HeaderContainer>
  )
}
