import { styled } from "@mui/material/styles";
import { DeviceIframe } from './device-iframe.styled';

const DeviceInner = styled('div')({
  width: '100%',
  height: '100%',
});

const DesktopIframe = styled(DeviceIframe)({
  width: 1200,
  height: '705.416114px',
  transform: 'scale(0.63083333)',
  backgroundColor: '#fff',
});

export function Desktop({origin}: {origin: string}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 958.93 565.982"
    >
      <g
        id="Group_4063"
        data-name="Group 4063"
        transform="translate(-120 -151)"
      >
        <rect
          id="Rectangle_8833"
          data-name="Rectangle 8833"
          width="757"
          height="26"
          transform="translate(221 189)"
          fill="#f6f6f6"
        />
        <rect
          id="Rectangle_8834"
          data-name="Rectangle 8834"
          width="251"
          height="16"
          rx="6"
          transform="translate(474 194)"
          fill="#c1c1c0"
        />
        <path
          id="Path_2362"
          data-name="Path 2362"
          d="M1305.726,835.578H386.372a6.475,6.475,0,0,0-.921-.337c-.908-.187-1.828-.3-2.73-.508a29.99,29.99,0,0,1-9-3.575,18.61,18.61,0,0,1-4.276-3.532,11.285,11.285,0,0,1-2.69-5.023,2.191,2.191,0,0,0-.275-.737c-.092-.138-.321-.187-.491-.275V808.8a2.253,2.253,0,0,0,.5-.3,1.719,1.719,0,0,0,.282-.518,4.233,4.233,0,0,1,4.224-2.756c.665,0,1.334,0,2,0h79.737c.6,0,1.2,0,1.8-.007.2,0,.4-.033.6-.052a.561.561,0,0,0,.351-.429c-.088-.724-.216-1.448-.249-2.176q-.088-1.991-.085-3.994,0-251,0-501.99c0-.734.017-1.468,0-2.2a19.147,19.147,0,0,1,.537-4.758,24.913,24.913,0,0,1,13.363-17.246A24.173,24.173,0,0,1,476.417,270c.39-.062.806-.059,1.088-.4h736.684a2.637,2.637,0,0,0,1.318.406A25.089,25.089,0,0,1,1236.661,293c.066,1.262.108,2.526.108,3.791q0,250.638.01,501.275a59.745,59.745,0,0,1-.282,5.983c-.02.2-.046.4-.059.593a.477.477,0,0,0,.455.534c.731.023,1.461.043,2.2.043q39.96,0,79.917,0c.668,0,1.334.006,2,0a4.032,4.032,0,0,1,3.454,1.658c.121.161.233.311.455.321v16.39c-.118.154-.308.295-.338.465a5.71,5.71,0,0,1-1.016,2.14,15.212,15.212,0,0,1-2.8,3.093,23.705,23.705,0,0,1-6.481,3.84,32.434,32.434,0,0,1-7.464,2.058C1306.427,835.243,1306.014,835.234,1305.726,835.578Zm-459.68-30.362q187.561,0,375.117-.007c3.542,0,3.025.5,3.025-3.08q.015-251.7.01-503.4c0-.665-.007-1.33,0-2a15.612,15.612,0,0,0-.79-4.909,16.537,16.537,0,0,0-14.811-11.541c-1.2-.039-2.4-.052-3.6-.052q-358.825,0-717.65,0c-.734,0-1.468-.007-2.2,0-.665.01-1.33.039-2,.059a14.585,14.585,0,0,0-5.4,1.294,16.425,16.425,0,0,0-9.945,13.91c-.046,1.065-.082,2.13-.082,3.195q0,251.6,0,503.2c0,.665,0,1.334.007,2,.007.265.046.528.075.793a.609.609,0,0,0,.541.492c.865.023,1.73.046,2.6.046Z"
          transform="translate(-245.984 -118.596)"
          fill="#aaa"
        />
        <rect
          id="Rectangle_8826"
          data-name="Rectangle 8826"
          width="756.477"
          height="44.361"
          transform="translate(221.729 659.213)"
          fill="#aaa"
        />
        <rect
          id="Rectangle_8827"
          data-name="Rectangle 8827"
          width="764.133"
          height="27.361"
          transform="translate(218.355 161.627)"
          fill="#aaa"
        />
        <foreignObject x="221" y="215" width="757" height="445">
          <DeviceInner>
            <DesktopIframe src={origin} />
          </DeviceInner>
        </foreignObject>
      </g>
    </svg>
  );
}
