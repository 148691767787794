import CropIcon from '@mui/icons-material/Crop';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';

const FooterContainer = styled('div')(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.common.white,
}));

export function Footer() {
  return (
    <FooterContainer>
      {/*<Button variant="text">*/}
      {/*  Screenshot Viewport Only*/}
      {/*  <CropIcon sx={{ marginLeft: '0.5rem' }} />*/}
      {/*</Button>*/}
      {/*<Button variant="text">*/}
      {/*  Screenshot Entire Page*/}
      {/*  <HorizontalSplitIcon*/}
      {/*    sx={{ marginLeft: '0.5rem', transform: 'rotate(180deg)' }}*/}
      {/*  />*/}
      {/*</Button>*/}
    </FooterContainer>
  );
}
