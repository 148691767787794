import { styled, ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { Header } from './layout/Header';
import { Footer } from './layout/Footer';
import { ShowcaseContainer } from './layout/ShowcaseContainer';
import GlobalStyles from '@mui/material/GlobalStyles';
import {useMemo, useState} from "react";

const headerHeight = 83;
const footerHeight = 36.5;

const globalBodyStyles = <GlobalStyles styles={{body: { overflow: 'auto', margin: 0 }}} />

const FixedHeader = styled('div')({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  height: headerHeight,
  zIndex: 1000,
});

const FixedContent = styled('div')({
  position: 'absolute',
  top: headerHeight,
  left: 0,
  right: 0,
  minHeight: `calc(100vh - ${headerHeight}px + ${footerHeight}px)`,
  overflowY: 'auto',
  zIndex: 100,
  '& > div': {
    marginBottom: footerHeight,
  }
});

const FixedFooter = styled('div')({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  height: footerHeight,
  zIndex: 1000,
});

export function App() {
  const [isMobileVisible, setIsMobileVisible] = useState(true);
  const [isTabletVisible, setIsTabletVisible] = useState(true);
  const [isDesktopVisible, setIsDesktopVisible] = useState(true);

  const origin = useMemo(() => {
    if (window.location.pathname === '/version-b') {
      return 'https://demoalpha.d3vcloud.com/#pre-auth/login';
    } else if (window.location.pathname === '/version-c') {
      return 'https://fi8-demoalpha.d3vcloud.com/';
    } else {
      return 'https://demotest.d3vcloud.com/#pre-auth/login';
    }
  }, [window.location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      {globalBodyStyles}
      <FixedHeader>
        <Header
          onMobileToggled={(on) => setIsMobileVisible(on)}
          onTabletToggled={(on) => setIsTabletVisible(on)}
          onDesktopToggled={(on) => setIsDesktopVisible(on)}
        />
      </FixedHeader>
      <FixedContent>
        <ShowcaseContainer
          origin={origin}
          showMobile={isMobileVisible}
          showTablet={isTabletVisible}
          showDesktop={isDesktopVisible}
        />
      </FixedContent>
      <FixedFooter>
        <Footer />
      </FixedFooter>
    </ThemeProvider>
  );
}
