import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import { Desktop } from '../devices/Desktop';
import { Tablet } from '../devices/Tablet';
import { useEffect, useState } from 'react';
import { Mobile } from '../devices/Mobile';

const transitionDuration = 250;

const Container = styled('div')({
  marginTop: '1.5rem',
  padding: '0 2rem',
});

const InfoDeviceWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

const DeviceWrapper = styled('div')({
  transition: `flex-basis ${transitionDuration}ms linear`,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  maxWidth: '1400px',
  margin: '0 auto',
});

const InfoWrapper = styled('div')({
  transition: `flex-basis ${transitionDuration}ms linear, margin-right ${transitionDuration}ms linear`,
});

const InfoText = styled('div')({
  overflow: 'hidden',
  transition: `opacity ${transitionDuration}ms linear, max-width ${transitionDuration}ms linear`,
  '& p': {
    marginBottom: '1rem',
  },
});

const MobileWebContainer = styled('div')({
  flex: '1 1 10%',
  zIndex: 200,
});

const DesktopContainer = styled('div')({
  flex: '1 1 70%',
  zIndex: 100,
  margin: '0 -150px 1.5rem -150px',
});

const TabletContainer = styled('div')({
  flex: '1 1 20%',
  zIndex: 200,
});

const ToggleInfoButton = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '1rem',
  '& label': {
    marginLeft: '0.5rem',
  },
});

export type ShowcaseContainerProps = {
  origin: string,
  showMobile: boolean,
  showTablet: boolean,
  showDesktop: boolean,
};

export function ShowcaseContainer(props: ShowcaseContainerProps) {
  const [infoVisible, setInfoVisible] = useState(false);
  const [wrapperIsVisible, setWrapperIsVisible] = useState(false);
  const [textIsVisible, setTextIsVisible] = useState(false);

  const handleInfoClick = () => {
    setInfoVisible(!infoVisible);
  };

  useEffect(() => {
    const immediateTransition = infoVisible ? setWrapperIsVisible : setTextIsVisible;
    const delayedTransition = infoVisible ? setTextIsVisible : setWrapperIsVisible;

    immediateTransition(infoVisible);
    let timeout = setTimeout(() => {
      delayedTransition(infoVisible);
    }, transitionDuration + 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [infoVisible]);

  return (
    <Container>
      <ToggleInfoButton>
        <IconButton
          onClick={handleInfoClick}
          sx={{ color: '#1C3C6A' }}
          id="toggle-info"
        >
          {infoVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
        <label htmlFor="toggle-info">
          <Typography variant="body2">Info</Typography>
        </label>
      </ToggleInfoButton>
      <InfoDeviceWrapper>
        <InfoWrapper
          sx={{
            flexBasis: wrapperIsVisible ? '20%' : '0%',
            marginRight: wrapperIsVisible ? '4rem' : 0,
          }}
        >
          <InfoText sx={{
              height: wrapperIsVisible ? 'auto': 0,
              maxWidth: wrapperIsVisible ? 1000 : 0,
              opacity: textIsVisible ? 1 : 0,
            }}>
            <Typography variant="body1">
              On the accounts screen, find the account you’re looking for by
              swiping through your account cards. When you have found the one
              you want, simply tap it.
            </Typography>
            <Typography variant="body1">
              Now you are in your account details screen, where you can find all
              of the relevant account info and tools as well as transaction
              data.
            </Typography>
            <Typography variant="body1">
              View your transaction details by tapping into a transaction in the
              list below.
            </Typography>
          </InfoText>
        </InfoWrapper>
        <DeviceWrapper
          sx={{
            flexBasis: wrapperIsVisible ? '80%' : '100%',
          }}
        >
          <MobileWebContainer style={{opacity: props.showMobile ? 1 : 0, transition: 'opacity 300ms'}}>
            <Mobile
              origin={'https://appetize.io/embed/0q48x9aqxav5uep4yxjjctypqg?device=iphone12&screenOnly=true&scale=96&orientation=portrait'}
            />
          </MobileWebContainer>
          <DesktopContainer style={{opacity: props.showDesktop ? 1 : 0, transition: 'opacity 300ms'}}>
            <Desktop origin={props.origin} />
          </DesktopContainer>
          <TabletContainer style={{opacity: props.showTablet ? 1 : 0, transition: 'opacity 300ms'}}>
            <Tablet origin={props.origin} />
          </TabletContainer>
        </DeviceWrapper>
      </InfoDeviceWrapper>
    </Container>
  );
}
